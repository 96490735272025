<template>
    <div class="flex flex-col w-full">
        <span class="text-5xl ml-20 mt-16 text-mainBlue font-bold">Mains</span>
        <recipe-display class="self-center" :recipeData="mainsData" foodType="main"></recipe-display>
    </div>
</template>

<script>
    import { getRecipes } from "../db/getters"
    import recipeDisplay from "../components/recipeDisplay.vue"

    export default {
        components: {
            "recipe-display" : recipeDisplay,
        },
        data() {
            return {
                mainsData: null,
            }
        },
        methods: {
            async getMainsData() {
                const data = await getRecipes("main");
                let mainsData = [];
                data.forEach((doc) => {
                    mainsData.push(doc.data())
                });
                return mainsData
            }
        },
        async mounted() {
            const data = await this.getMainsData();
            this.$store.commit('setCurrentRecipes', data);
            this.mainsData = data;
        },
    }
</script>
<template>
    <div class="flex flex-col w-full">
        <span class="text-5xl ml-20 mt-16 text-mainBlue font-bold">Appetizers</span>
        <recipe-display class="self-center" :recipeData="appetizerData" foodType="appetizer"></recipe-display>
    </div>
</template>

<script>
    import { getRecipes } from "../db/getters"
    import recipeDisplay from "../components/recipeDisplay.vue"

    export default {
        components: {
            "recipe-display" : recipeDisplay,
        },
        data() {
            return {
                appetizerData: null,
            }
        },
        methods: {
            async getAppetizerData() {
                const data = await getRecipes("appetizer");
                let appetizerData = [];
                data.forEach((doc) => {
                    appetizerData.push(doc.data())
                });
                return appetizerData
            }
        },
        async mounted() {
            const data = await this.getAppetizerData();
            this.$store.commit('setCurrentRecipes', data);
            this.appetizerData = data;
        },
    }
</script>
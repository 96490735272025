<template>
    <div class="flex flex-col w-full">
        <span class="text-5xl ml-20 mt-16 text-mainBlue font-bold">Dessert</span>
        <recipe-display class="self-center" :recipeData="dessertsData" foodType="dessert"></recipe-display>
    </div>
</template>

<script>
    import { getRecipes } from "../db/getters"
    import recipeDisplay from "../components/recipeDisplay.vue"

    export default {
        components: {
            "recipe-display" : recipeDisplay,
        },
        data() {
            return {
                dessertsData: null,
            }
        },
        methods: {
            async getDessertsData() {
                const data = await getRecipes("dessert");
                let dessertsData = [];
                data.forEach((doc) => {
                    dessertsData.push(doc.data())
                });
                return dessertsData
            }
        },
        async mounted() {
            const data = await this.getDessertsData();
            this.$store.commit('setCurrentRecipes', data);
            this.dessertsData = data;
        },
    }
</script>
<template>
  <div class="flex justify-center mt-16">
    <div class="w-1/2 flex flex-col self-center mb-20">
      <div class="flex flex-row">
        <i class="fa-solid fa-plus fa-4x" style="color: #7cb5c6"></i>
        <span class="font-bold text-5xl mt-8">Add Recipe</span>
      </div>
      <div class="border border-y-mainBlue my-10"></div>
      <div class="flex flex-row w-11/12 self-center">
        <div class="flex flex-col w-2/3">
          <div class="flex flex-col ml-2 mb-5">
            <span class="text-lg ml-1 font-medium">Recipe Title</span>
            <textarea
              class="border-2 rounded-md h-10 pl-2 pt-1 text-gray-700 form-control"
              :class="title_empty ? 'border-red-500' : 'border-gray-500'"
              id="e-textarea"
              placeholder="Give your recipe a title"
              :value="recipe.name"
              @input="recipe.name = $event.target.value"
            >
            </textarea>
            <span v-if="title_empty" class="text-xs text-red-500 ml-1">
              Recipe Title Required
            </span>
          </div>
          <div class="flex flex-col ml-2 mb-5">
            <span class="text-lg ml-1 font-medium">Recipe Description</span>
            <textarea
              class="border-2 border-gray-500 rounded-md h-20 pl-2 pt-1 text-gray-700 form-control"
              id="e-textarea"
              placeholder="Give your recipe a description"
              :value="recipe.description"
              @input="recipe.description = $event.target.value"
            >
            </textarea>
          </div>
          <div class="flex flex-col ml-2 mb-5">
            <span class="text-lg ml-1 font-medium">Recipe Type</span>
            <div>
              <select
                v-model="selected"
                class="border-2 w-3/4 h-6 rounded-md"
                :class="empty_selection ? 'border-red-500' : 'border-gray-500'"
              >
                <option
                  v-for="option in options"
                  :value="option.value"
                  :key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <span v-if="empty_selection" class="text-xs text-red-500 ml-1">
              Recipe Type Required
            </span>
          </div>
        </div>
        <div class="flex flex-col ml-12 w-1/3">
          <span class="text-lg font-medium ml-2">Image</span>
          <input
            type="file"
            style="display: none"
            ref="imageInput"
            accept="image/*"
            @change="onImagePicked"
          />
          <i
            v-if="!recipe.image"
            class="fa-regular fa-image fa-10x cursor-pointer -mt-2"
            style="color: rgb(31 41 55)"
            @click="onPickedImage"
          >
          </i>
          <img
            v-if="recipe.image"
            :src="recipe.image"
            class="cursor-pointer"
            @click="onPickedImage"
          />
          <span v-if="!recipe.image" class="text-xs text-gray-400 -mt-1">
            Use JPEG or PNG
          </span>
        </div>
      </div>
      <div class="border border-y-mainBlue my-10"></div>
      <div class="flex flex-col w-11/12 self-center">
        <span class="text-3xl font-medium">Ingredients</span>
        <span class="text-sm mt-2"
          >Enter one ingredient per line. Include the quantity (i.e. cups,
          tablespoons) and any special preparation (i.e. sifted, softened,
          chopped).
        </span>
        <div class="flex flex-col mt-5">
          <div
            v-for="(ingredient, index) in recipe.ingredients"
            :key="index"
            class="flex flex-row mb-4"
          >
            <textarea
              class="border-2 border-gray-500 rounded-md h-10 pl-2 pt-1 text-gray-700 form-control w-2/3 mr-2"
              id="e-textarea"
              placeholder="Add Ingredient"
              :value="ingredient"
              @input="recipe.ingredients[index] = $event.target.value"
            >
            </textarea>
            <i
              class="fa-regular fa-circle-xmark fa-xl cursor-pointer mt-4"
              @click="removeIngredient(index)"
            ></i>
          </div>
          <button
            @click="addIngredient"
            class="cursor-pointer w-36 h-8 justify-center text-center rounded-md border-2 border-gray-500 hover:bg-mainBlue hover:border-mainBlue"
          >
            Add Ingredient
          </button>
        </div>
      </div>
      <div class="border border-y-mainBlue my-10"></div>
      <div class="flex flex-col w-11/12 self-center">
        <span class="text-3xl font-medium">Directions</span>
        <span class="text-sm mt-2"
          >Explain how to make your recipe, including oven temperatures, baking
          or cooking times, and pan sizes, etc.
        </span>
        <div class="flex flex-col mt-5">
          <div v-for="(step, index) in recipe.steps" :key="index" class="mb-4">
            <div class="flex flex-col">
              <p>Step {{ index + 1 }}</p>
              <div class="flex flex-row">
                <textarea
                  class="border-2 border-gray-500 rounded-md h-20 pl-2 pt-1 text-gray-700 form-control w-full mr-2"
                  id="e-textarea"
                  placeholder="Add Step"
                  :value="step"
                  @input="recipe.steps[index] = $event.target.value"
                >
                </textarea>
                <i
                  class="fa-regular fa-circle-xmark fa-xl cursor-pointer mt-10"
                  @click="removeStep(index)"
                ></i>
              </div>
            </div>
          </div>
          <button
            @click="addStep"
            class="cursor-pointer w-36 h-8 justify-center text-center rounded-md border-2 border-gray-500 hover:bg-mainBlue hover:border-mainBlue"
          >
            Add Step
          </button>
        </div>
      </div>
      <div class="border border-y-mainBlue my-10"></div>
      <div class="flex flex-col w-11/12 self-center">
        <span class="text-3xl font-medium">Notes:</span>
        <div class="flex flex-row">
          <textarea
            class="border-2 border-gray-500 rounded-md h-20 pl-2 pt-1 mt-2 text-gray-700 form-control w-full mr-2"
            id="e-textarea"
            placeholder="Add Additional Notes"
            :value="recipe.notes"
            @input="recipe.notes = $event.target.value"
          >
          </textarea>
        </div>
      </div>
      <div class="border border-y-mainBlue my-10"></div>
      <button
        class="cursor-pointer w-52 h-10 self-center rounded-md border-2 border-gray-500 hover:bg-mainBlue hover:border-mainBlue"
        @click="save_recipe"
      >
        Add Recipe
      </button>
    </div>
  </div>
  <error-notification
    :errorMSG="error_message"
    :show="toggle_error"
    @close_error="close_error"
  >
  </error-notification>
</template>

<script>
import { addRecipe } from "../db/setters";
import errorNotification from "../components/errorNotification.vue";

export default {
  components: {
    "error-notification": errorNotification,
  },
  data() {
    return {
      recipe: {
        name: "",
        description: "",
        ingredients: [""],
        steps: [""],
        image: null,
        notes: "",
      },
      toggle_error: false,
      error_message: "",
      imageURL: "",
      selected: "",
      options: [
        { text: "Breakfast", value: "breakfast" },
        { text: "Appetizer", value: "appetizer" },
        { text: "Main", value: "main" },
        { text: "Side", value: "side" },
        { text: "Dessert", value: "dessert" },
        { text: "Cocktail", value: "cocktails" },
      ],
      title_empty: true,
    };
  },
  computed: {
    empty_selection() {
      return this.selected === "";
    },
  },
  methods: {
    removeIngredient(index) {
      if (this.recipe.ingredients.length === 0) {
        return;
      } else if (this.recipe.ingredients.length === 1) {
        this.recipe.ingredients = [];
      } else {
        this.recipe.ingredients.splice(index, 1);
      }
    },
    close_error() {
      this.toggle_error = false;
      this.error_message = "";
    },
    addIngredient() {
      this.recipe.ingredients.push("");
    },
    removeStep(index) {
      if (this.recipe.steps.length === 0) {
        return;
      } else if (this.recipe.steps.length === 1) {
        this.recipe.steps = [];
      } else {
        this.recipe.steps.splice(index, 1);
      }
    },
    addStep() {
      this.recipe.steps.push("");
    },
    onPickedImage() {
      this.$refs.imageInput.click();
    },
    onImagePicked(event) {
      const file = event.target.files[0];
      let name = file.name;
      if (name.lastIndexOf(".") <= 0) return;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.recipe.image = fileReader.result;
      });
      fileReader.readAsDataURL(file);
    },
    async save_recipe() {
      if (this.empty_selection || this.title_empty) {
        scroll(0, 0);
        this.error_message = "Empty Section";
        this.toggle_error = true;
        return;
      }
      const url = "/" + this.selected;
      const error = await addRecipe(this.recipe, this.selected);
      if (!error) {
        this.$router.push({
          path: url,
        });
      } else {
        this.error_message = "Upload Failed";
        this.toggle_error = true;
      }
    },
  },
  watch: {
    recipe: {
      handler() {
        this.title_empty =
          this.recipe.name === undefined || this.recipe.name === "";
      },
      deep: true,
    },
  },
};
</script>
<style>
::placeholder {
  color: rgb(55 65 81);
}
</style>

import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    recipeType: "",
    recipes: [],
  },
  mutations: {
    setCurrentRecipes(state, newRecipe) {
      state.recipes = newRecipe;
    },
    setCurrentRecipeType(state, newType) {
      state.recipeType = newType;
    },
    updateRecipe(state, newRecipe) {
      const currentRecipeInbdex = state.recipes.findIndex(currentRecipe => currentRecipe.name === newRecipe.name);
      state.recipes[currentRecipeInbdex] = newRecipe;
    }
  },
  getters: {
    getRecipe: (state) => (recipeName) =>{
      return state.recipes.find(currentRecipe => currentRecipe.name === recipeName);
    },
  },
  modules: {
  },
  plugins: [createPersistedState({
    paths: ['recipes', 'recipeType']
  })],
})

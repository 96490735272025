<template>
    <div class="flex flex-col w-full">
        <span class="text-5xl ml-20 mt-16 text-mainBlue font-bold">Breakfast</span>
        <recipe-display class="self-center" :recipeData="breakfastData" foodType="breakfast"></recipe-display>
    </div>
</template>

<script>
    import { getRecipes } from "../db/getters"
    import recipeDisplay from "../components/recipeDisplay.vue"

    export default {
        components: {
            "recipe-display" : recipeDisplay,
        },
        data() {
            return {
                breakfastData: null,
            }
        },
        methods: {
            async getBreakfastData() {
                const data = await getRecipes("breakfast");
                let breakfastData = [];
                data.forEach(doc => {
                    breakfastData.push(doc.data());
                })
                return breakfastData
            }
        },
        async mounted() {
            const data = await this.getBreakfastData();
            this.$store.commit('setCurrentRecipes', data);
            this.breakfastData = data;
        },
    }
</script>
<template>
  <div class="flex flex-col w-full">
    <span class="text-5xl ml-20 mt-16 text-mainBlue font-bold">Cocktails</span>
    <recipe-display
      class="self-center"
      :recipeData="cocktailsData"
      foodType="cocktails"
    ></recipe-display>
  </div>
</template>

<script>
import { getRecipes } from "../db/getters";
import recipeDisplay from "../components/recipeDisplay.vue";

export default {
  components: {
    "recipe-display": recipeDisplay,
  },
  data() {
    return {
      cocktailsData: null,
    };
  },
  methods: {
    async getCocktailsData() {
      const data = await getRecipes("cocktails");
      let appetizerData = [];
      data.forEach((doc) => {
        appetizerData.push(doc.data());
      });
      return appetizerData;
    },
  },
  async mounted() {
    const data = await this.getCocktailsData();
    this.$store.commit("setCurrentRecipes", data);
    this.cocktailsData = data;
  },
};
</script>

<template>
  <div
    v-if="isHamburger"
    class="flex flex-row h-12 w-full bg-mainBlue justify-center align-middle"
  >
    <div class="mt-3" v-for="route in routes" :key="route.name">
      <router-link
        v-if="route.shouldShow"
        class="mx-6 hover:text-white"
        :to="route.path"
        >{{ route.name }}</router-link
      >
    </div>
    <button
      class="mx-6 hover:text-white"
      @click="handleSignOut"
      v-if="is_authenticated"
    >
      Sign Out
    </button>
  </div>
  <div v-else>
    <div
      v-if="showHamburger"
      class="absolute right-4 top-4 cursor-pointer"
      @click="toggleHamburger"
    >
      <i class="fa-solid fa-bars fa-xl" style="color: rgb(124, 181, 198)"></i>
    </div>
    <div
      v-if="!showHamburger"
      class="absolute w-56 z-0 h-full right-0 top-0 bg-mainBlue"
    >
      <i
        class="relative fa-solid fa-xmark fa-xl top-4 left-48 cursor-pointer"
        @click="toggleHamburger"
      ></i>
      <div class="flex flex-col mt-5 justify-center">
        <div v-for="route in routes" :key="route.name">
          <div v-if="route.shouldShow" class="my-3"></div>
          <router-link
            v-if="route.shouldShow"
            @click="closeHamburger"
            class="mx-6 hover:text-white"
            :to="route.path"
            >{{ route.name }}</router-link
          >
        </div>
      </div>
      <button
        class="mx-6 mt-3 hover:text-white"
        @click="handleSignOut"
        v-if="is_authenticated"
      >
        Sign Out
      </button>
    </div>
  </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import { useRouter } from "vue-router";

export default {
  data() {
    return {
      is_authenticated: sessionStorage.getItem("is_auth"),
      auth: getAuth(),
      router: useRouter(),
      isHamburger: window.innerWidth > 735,
      showHamburger: true,
    };
  },
  methods: {
    handleSignOut() {
      sessionStorage.removeItem("currentUser");
      this.router.push("/");
      this.auth.signOut();
    },
    onResize() {
      this.isHamburger = window.innerWidth > 735;
    },
    toggleHamburger() {
      this.showHamburger = !this.showHamburger;
    },
    closeHamburger() {
      this.showHamburger = true;
    },
  },
  computed: {
    routes() {
      return [
        { path: "/", name: "Home", shouldShow: true },
        { path: "/breakfast", name: "Breakfast", shouldShow: true },
        { path: "/appetizer", name: "Appetizer", shouldShow: true },
        { path: "/main", name: "Main", shouldShow: true },
        { path: "/side", name: "Side", shouldShow: true },
        { path: "/dessert", name: "Dessert", shouldShow: true },
        { path: "/cocktails", name: "Cocktails", shouldShow: true },
        {
          path: "/signin",
          name: "Sign In",
          shouldShow: !this.is_authenticated,
        },
        {
          path: "/addRecipe",
          name: "Add Recipe",
          shouldShow: this.is_authenticated,
        },
      ];
    },
  },
  mounted() {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        sessionStorage.setItem("is_auth", true);
        window.dispatchEvent(
          new CustomEvent("set_auth", {
            detail: {
              is_authenticated: true,
            },
          })
        );
      } else {
        if (this.router.currentRoute.name === "Add Recipe") {
          this.router.push("/");
        }
        sessionStorage.removeItem("is_auth");
        window.dispatchEvent(
          new CustomEvent("set_auth", {
            detail: {
              is_authenticated: false,
            },
          })
        );
      }
    }),
      window.addEventListener("set_auth", (event) => {
        this.is_authenticated = event.detail.is_authenticated;
      });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<template>
  <navbar></navbar>
  <router-view />
</template>

<script>
import "firebase/auth";
import navBar from "./components/navBar.vue";
import { migrationJan9 } from "./db/migrations";

export default {
  components: {
    navbar: navBar,
  },
  methods: {
    migration() {
      migrationJan9();
    },
  },
};
</script>

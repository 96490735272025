<template>
    <div v-if="show" class="h-16 w-72 bg-gray-800 absolute right-12 top-4 rounded-md flex flex-row items-center">
        <span class="text-white w-56 ml-7 text-lg"> {{  errorMSG }}</span>
        <i 
            class="fa-solid fa-x cursor-pointer" 
            style="color:rgb(255,255,255);"
            @click="$emit('close_error')"
        ></i>
    </div>
</template>

<script>
    export default {
        props: {
            show: {
                default: false,
                type: Boolean,
            },
            errorMSG: {
                default: "",
                type: String,
            }
        },
    }
</script>
import { createWebHistory, createRouter } from "vue-router";
import { getAuth } from "firebase/auth";
import Home from "@/pages/HomePage.vue";
import AddRecipe from "@/pages/AddRecipe.vue";
import SignIn from "@/pages/SignIn.vue";
import BreakFeast from "@/pages/BreakfastPage.vue";
import RecipePage from "@/pages/RecipePage.vue"
import Appetizer from "@/pages/AppetizerPage.vue";
import Mains from "@/pages/MainsPage.vue"
import Sides from "@/pages/SidesPage.vue"
import Desserts from "@/pages/DessertsPage.vue"
import Cocktails from "@/pages/CocktailsPage.vue"

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/addRecipe",
        name: "Add Recipe",
        component: AddRecipe,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/signin",
        name: "Sign In",
        component: SignIn,
    },
    {
        path: "/breakfast",
        name: "Breakfast",
        component: BreakFeast,
    },
    {
        path: "/appetizer",
        name: "Appetizer",
        component: Appetizer,
    },
    {
        path: "/main",
        name: "Main",
        component: Mains,
    },
    {
        path: "/side",
        name: "Side",
        component: Sides,
    },
    {
        path: "/dessert",
        name: "Dessert",
        component: Desserts,
    },
    {
        path: "/cocktails",
        name: "Cocktails",
        component: Cocktails,
    },
    {
        path: "/breakfast/:recipe",
        name: "Breakfast Recipe",
        component: RecipePage,
    },
    {
        path: "/appetizer/:recipe",
        name: "Appetizer Recipe",
        component: RecipePage,
    },
    {
        path: "/main/:recipe",
        name: "Main Recipe",
        component: RecipePage,
    },
    {
        path: "/side/:recipe",
        name: "Side Recipe",
        component: RecipePage,
    },
    {
        path: "/dessert/:recipe",
        name: "Dessert Recipe",
        component: RecipePage,
    },
    {
        path: "/cocktails/:recipe",
        name: "Cocktail Recipe",
        component: RecipePage,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = getAuth().currentUser;
    if (requiresAuth && !isAuthenticated) {
        next("/");
    } else {
        next();
    }
});

export default router;

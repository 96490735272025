import { ref, getStorage, getDownloadURL } from "firebase/storage"
import { query, where, getDocs } from "firebase/firestore";
import { recipesCollection } from "../main"

/**
 * Gets recipies of a given category
 * @param recipeCategory the category of recipe
 * @returns a list of the recipes
 */
export async function getRecipes(recipeCategory) {
    const q = query(recipesCollection, where("type", "==", recipeCategory));
    const querySnapshot = await getDocs(q);
    return querySnapshot; 
}

/**
 * Gets the image url
 * @param path the path to the image
 * @param name the name of the image
 * @returns the url of the image or null if not found
 */
export async function getRecipeImage(path, name) {
    try {
        const image_name = path + "/" + name;
        const storage = getStorage();
        const storageRef = ref(storage, image_name);
        return await getDownloadURL(storageRef);
    } catch (error) {
        return null;
    }
}
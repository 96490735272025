import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeApp } from "firebase/app";
import "firebase/firestore";
import { getFirestore, collection } from "firebase/firestore";
import './assets/tailwind.css';
import store from './store';
import VueGtag from "vue-gtag";

// Configuration for firebase
const firebaseConfig = {
    apiKey: "AIzaSyBJ7P_5ORjNF-xE0maGPBuGQT2p9rVypa0",
    authDomain: "cookbook-ef7ff.firebaseapp.com",
    projectId: "cookbook-ef7ff",
    storageBucket: "cookbook-ef7ff.appspot.com",
    messagingSenderId: "435641271293",
    appId: "1:435641271293:web:03f87ceafe232fc47a8f94",
    measurementId: "G-FNR65G6MM1"
  };

// Initialize APP with firebase
initializeApp(firebaseConfig);
// Add store to site
const app = createApp(App).use(store);
// Add router to site
app.use(router);
// Add analytics to the site
app.use(VueGtag, {
    config: { 
      id: firebaseConfig.measurementId,
    },
  }, router);
// Mount the app
app.mount('#app');

// Initialize the database and collections
export const db = getFirestore();
export const recipesCollection = collection(db, 'recipes')
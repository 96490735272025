<template>
  <div class="flex flex-row justify-center">
    <div class="flex flex-col w-3/4 mt-14">
      <div class="flex flex-row justify-between text-mainBlue font-bold">
        <span class="text-6xl border-b-2 border-mainBlue w-fit mb-5">{{
          recipe.name
        }}</span>
        <div
          v-if="canEdit && !recipeEditor"
          class="hover:text-gray-800 cursor-pointer mt-10"
          @click="toggleEdit"
        >
          Edit
        </div>
      </div>
      <div v-if="!recipeEditor">
        <div v-if="recipe.description" class="mb-5">
          <span class="text-xl">{{ recipe.description }}</span>
        </div>
        <img v-if="imgUrl" :src="imgUrl" class="w-52 mb-5 self-center" />
        <div
          v-if="recipe.ingredients.length > 0 && recipe.ingredients[0] != ''"
          class="mb-5"
        >
          <span class="text-xl"> Ingredients </span>
          <li v-for="ingredient in recipe.ingredients" :key="ingredient">
            {{ ingredient }}
          </li>
        </div>
        <div
          v-if="recipe.steps.length > 0 && recipe.steps[0] != ''"
          class="mb-5"
        >
          <span class="text-xl"> Steps </span>
          <div v-for="(step, index) in recipe.steps" :key="step">
            <div class="flex flex-row">
              <span class="mr-2">{{ index + 1 + "." }}</span>
              <span>{{ step }}</span>
            </div>
          </div>
        </div>
        <div v-if="recipe.notes" class="mb-5 flex flex-col">
          <span class="text-xl"> Notes: </span>
          <span class="text-md">{{ recipe.notes }}</span>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-col w-1/3">
          <span class="text-2xl font-medium">Image</span>
          <input
            type="file"
            style="display: none"
            ref="imageInput"
            accept="image/*"
            @change="onImagePicked"
          />
          <i
            v-if="!newImage"
            class="fa-regular fa-image fa-10x cursor-pointer -mt-2"
            style="color: rgb(31 41 55)"
            @click="onPickedImage"
          >
          </i>
          <img
            v-if="newImage"
            :src="newImage"
            class="cursor-pointer"
            @click="onPickedImage"
          />
          <span v-if="!newImage" class="text-xs text-gray-400 -mt-1">
            Use JPEG or PNG
          </span>
        </div>
        <div class="flex flex-col my-5">
          <span class="text-2xl font-medium">Recipe Description</span>
          <textarea
            class="border-2 border-gray-500 rounded-md h-20 pl-2 pt-1 text-gray-700 form-control w-1/2"
            id="e-textarea"
            placeholder="Give your recipe a description"
            :value="editedRecipe.description"
            @input="editedRecipe.description = $event.target.value"
          >
          </textarea>
        </div>
        <div class="flex flex-col w-11/12 self-center my-5">
          <span class="text-2xl font-medium">Ingredients</span>
          <span class="text-sm mt-1"
            >Enter one ingredient per line. Include the quantity (i.e. cups,
            tablespoons) and any special preparation (i.e. sifted, softened,
            chopped).
          </span>
          <div class="flex flex-col mt-3">
            <div
              v-for="(ingredient, index) in editedRecipe.ingredients"
              :key="index"
              class="flex flex-row mb-4"
            >
              <textarea
                class="border-2 border-gray-500 rounded-md h-10 pl-2 pt-1 text-gray-700 form-control w-2/3 mr-2"
                id="e-textarea"
                placeholder="Add Ingredient"
                :value="ingredient"
                @input="editedRecipe.ingredients[index] = $event.target.value"
              >
              </textarea>
              <i
                class="fa-regular fa-circle-xmark fa-xl cursor-pointer mt-4"
                @click="removeIngredient(index)"
              ></i>
            </div>
            <button
              @click="addIngredient"
              class="cursor-pointer w-36 h-8 justify-center text-center rounded-md border-2 border-gray-500 hover:bg-mainBlue hover:border-mainBlue"
            >
              Add Ingredient
            </button>
          </div>
        </div>
        <div class="flex flex-col w-11/12 self-center my-10">
          <span class="text-2xl font-medium">Directions</span>
          <span class="text-sm mt-1"
            >Explain how to make your recipe, including oven temperatures,
            baking or cooking times, and pan sizes, etc.
          </span>
          <div class="flex flex-col mt-2">
            <div
              v-for="(step, index) in editedRecipe.steps"
              :key="index"
              class="mb-3"
            >
              <div class="flex flex-col">
                <p>Step {{ index + 1 }}</p>
                <div class="flex flex-row">
                  <textarea
                    class="border-2 border-gray-500 rounded-md h-20 pl-2 pt-1 text-gray-700 form-control w-full mr-2"
                    id="e-textarea"
                    placeholder="Add Step"
                    :value="step"
                    @input="editedRecipe.steps[index] = $event.target.value"
                  >
                  </textarea>
                  <i
                    class="fa-regular fa-circle-xmark fa-xl cursor-pointer mt-10"
                    @click="removeStep(index)"
                  ></i>
                </div>
              </div>
            </div>
            <button
              @click="addStep"
              class="cursor-pointer w-36 h-8 justify-center text-center rounded-md border-2 border-gray-500 hover:bg-mainBlue hover:border-mainBlue"
            >
              Add Step
            </button>
          </div>
        </div>
        <div class="flex flex-col w-11/12 self-center my-10">
          <span class="text-2xl font-medium">Notes:</span>
          <div class="flex flex-row">
            <textarea
              class="border-2 border-gray-500 rounded-md h-20 pl-2 pt-1 mt-2 text-gray-700 form-control w-full mr-2"
              id="e-textarea"
              placeholder="Add Additional Notes"
              :value="editedRecipe.notes"
              @input="editedRecipe.notes = $event.target.value"
            >
            </textarea>
          </div>
        </div>
        <div class="flex flex-row my-10">
          <button
            class="cursor-pointer w-36 h-10 mr-6 self-center rounded-md border-2 border-gray-500 hover:bg-mainBlue hover:border-mainBlue"
            @click="saveRecipe"
          >
            Save Recipe
          </button>
          <button
            class="cursor-pointer w-36 h-10 self-center rounded-md hover:border-2 hover:border-gray-500 bg-mainBlue hover:bg-white"
            @click="cancelEdit"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <error-notification
    :errorMSG="error_message"
    :show="toggle_error"
    @close_error="close_error"
  >
  </error-notification>
</template>

<script>
import { getRecipeImage } from "../db/getters";
import { updateRecipe, updateRecipePhoto } from "../db/setters";
import errorNotification from "../components/errorNotification.vue";
export default {
  components: {
    "error-notification": errorNotification,
  },
  data() {
    return {
      imgUrl: null,
      recipe: this.$store.getters.getRecipe(this.$route.params.recipe),
      recipeEditor: false,
      editedRecipe: null,
      error_message: "",
      toggle_error: false,
      newImage: null,
    };
  },
  computed: {
    canEdit() {
      return sessionStorage.getItem("currentUser") === this.recipe.user;
    },
  },
  methods: {
    toggleEdit() {
      this.editedRecipe = this.recipe;
      this.recipeEditor = !this.recipeEditor;
    },
    cancelEdit() {
      this.editedRecipe = null;
      this.newImage = null;
      this.recipeEditor = !this.recipeEditor;
    },
    removeIngredient(index) {
      if (this.editedRecipe.ingredients.length === 0) {
        return;
      } else if (this.editedRecipe.ingredients.length === 1) {
        this.editedRecipe.ingredients = [];
      } else {
        this.editedRecipe.ingredients.splice(index, 1);
      }
    },
    addIngredient() {
      this.editedRecipe.ingredients.push("");
    },
    removeStep(index) {
      if (this.editedRecipe.steps.length === 0) {
        return;
      } else if (this.editedRecipe.steps.length === 1) {
        this.editedRecipe.steps = [];
      } else {
        this.editedRecipe.steps.splice(index, 1);
      }
    },
    addStep() {
      this.editedRecipe.steps.push("");
    },
    async saveRecipe() {
      const hasImage = this.imgUrl;
      const response = await updateRecipe(this.editedRecipe);
      if (!response) {
        this.recipe = this.editedRecipe;
        this.editedRecipe = null;
        this.$store.commit("updateRecipe", this.recipe);
        if (this.newImage) {
          updateRecipePhoto(this.newImage, this.recipe.name, hasImage);
        }
        this.newImage = null;
        this.recipeEditor = !this.recipeEditor;
      } else {
        scroll(0, 0);
        this.error_message = "Update Failed";
        this.toggle_error = true;
      }
    },
    close_error() {
      this.toggle_error = false;
      this.error_message = "";
    },
    onImagePicked(event) {
      const file = event.target.files[0];
      let name = file.name;
      if (name.lastIndexOf(".") <= 0) return;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.newImage = fileReader.result;
      });
      fileReader.readAsDataURL(file);
    },
    onPickedImage() {
      this.$refs.imageInput.click();
    },
  },
  async mounted() {
    this.imgUrl = await getRecipeImage("recipePhotos", this.recipe.name);
  },
};
</script>

<style>
#content img {
  max-width: 90px;
  height: auto;
}
</style>

<template>
    <div class="flex flex-col w-full">
        <span class="text-5xl ml-20 mt-16 text-mainBlue font-bold">Sides</span>
        <recipe-display class="self-center" :recipeData="sidesData" foodType="side"></recipe-display>
    </div>
</template>

<script>
    import { getRecipes } from "../db/getters"
    import recipeDisplay from "../components/recipeDisplay.vue"

    export default {
        components: {
            "recipe-display" : recipeDisplay,
        },
        data() {
            return {
                sidesData: null,
            }
        },
        methods: {
            async getSidesData() {
                const data = await getRecipes("side");
                let sidesData = [];
                data.forEach((doc) => {
                    sidesData.push(doc.data())
                });
                return sidesData
            }
        },
        async mounted() {
            const data = await this.getSidesData();
            this.$store.commit('setCurrentRecipes', data);
            this.sidesData = data;
        },
    }
</script>
import { db } from "../main"
import { ref, uploadString, getStorage, deleteObject } from "firebase/storage"
import {  doc, updateDoc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

/**
 * Adds recipes to the databse
 * @param recipe All data for the recipe
 * @param recipeCategory Category of the recipe
 * @returns Boolean if upload fails or not
 */
export async function addRecipe(recipe, recipeCategory) {
    let isError = false;
    if (recipe.image) {
        try {
            const img_name = "recipePhotos/" + recipe.name
            const storage = getStorage();
            const storageRef = ref(storage, img_name);
            uploadString(storageRef, recipe.image, 'data_url')
        } catch (error) {
            isError = true;
        }
    }
    try {
        delete recipe.image;
        recipe.type = recipeCategory;
        const auth = getAuth();
        recipe.user = auth.currentUser.email;
        recipe.username = auth.currentUser.displayName;
        recipe.userid = auth.currentUser.uid;
        setDoc(doc(db, "recipes", recipe.name), recipe)
    } catch (error) {
        isError =  true;
    }
    return isError;
}

export async function updateRecipe(recipe) {
    const recipeRef = doc(db, "recipes", recipe.name);
    try {
        await updateDoc(recipeRef, recipe);
        return false;
    } catch (error) {
        return true;
    }
}

export async function updateRecipePhoto(image, name, hasImage) {
    try {
        const img_name = "recipePhotos/" + name;
        const storage = getStorage();
        const storageRef = ref(storage, img_name);
        if (hasImage) {
            deleteObject(storageRef);
        }
        try {
            uploadString(storageRef, image, 'data_url');
        } catch (error) {
            return true;
        }
    } catch (error) {
        return true;
    }
}